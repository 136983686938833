import clsx from 'clsx';
import { ReactElement } from 'react';
import { ShareOnTwitter, Text, Link } from '@/components';
import ResponsiveText from '@/components/text/ResponsiveText';
import { ScamReportDetails } from '@/features';
import { useMe } from '@/hooks';
import {
  getRouteForEditReport,
  getRouteForReport,
  getUrlForReport,
} from '@/types/routes';
import {
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getScamReportCardPropsFromReport } from '@/utils/getScamReportCardPropsFromReport';
import { ScamReportDetailsProps } from '@/features';
import { PageType } from '@/types/page';
import { useReportSuccessDetailsQuery } from '@/generated/graphql';
import { PRIVATE_REPORT_DUMMY_DETAILS } from '@/dummy/results';

export type SuccessPageProps = StyleProps &
  ScamReportDetailsProps & {
    profileImgSrc?: string;
    isGuestReport: boolean;
  };

const ROOT = makeRootClassName('SuccessPage');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function SuccessPage(props: SuccessPageProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { me } = useMe();
  const isMyReport = !!me && me.username === p.authorUsername;

  return (
    <div className={clsx(ROOT, p.className)}>
      {p.isPrivate ? (
        <div className={el`header`}>
          <ResponsiveText type="h3" tabletType="h2" className={el`title`}>
            Thank you. We have received your report.
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={el`subtitle`}
          >
            {p.isGuestReport ? (
              <>
                As you submitted your report privately, you will not be able to
                see it on Chainabuse.com.
              </>
            ) : (
              <>
                As you submitted your report privately, you will not be able to
                see it on Chainabuse.com. However, you can edit it{' '}
                <Link href={getRouteForEditReport(p.reportId)}>here</Link>.
              </>
            )}
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={el`subtitle`}
          >
            If you opted-in to get support, check your mailbox within 30 minutes
            for an email from support@chainabuse.com with support on your case.
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={clsx(el`subtitle`, 'mt-[32px]')}
          >
            Your report will only be visible to our Law Enforcement Partners.
          </ResponsiveText>
        </div>
      ) : (
        <div className={el`header`}>
          <ResponsiveText type="h3" tabletType="h2" className={el`title`}>
            Thank you. Your report is now part of the database.
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={el`subtitle`}
          >
            Thank you for alerting the space and making web3 safer.{' '}
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={el`subtitle`}
          >
            {p.isGuestReport ? (
              <>
                You can view your report by clicking{' '}
                <Link href={getRouteForReport(p.reportId, PageType.BROWSE_ALL)}>
                  here
                </Link>
                {'.'}
              </>
            ) : (
              <>
                You can view and edit your report by navigating to &ldquo;View
                Reports&rdquo; in your profile.
              </>
            )}{' '}
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={el`subtitle`}
          >
            If you opted-in to get support, check your mailbox within 30 minutes
            for an email from support@chainabuse.com with support on your case.
          </ResponsiveText>

          <ResponsiveText
            type="body-md"
            tabletType="body-lg"
            className={clsx(el`subtitle`, 'mt-[32px]')}
          >
            For security reasons, we do not share evidence you uploaded on
            Chainabuse.com but it will be visible to Law Enforcement Partners.
          </ResponsiveText>
        </div>
      )}

      <ScamReportDetails
        {...p}
        {...(!isMyReport && p.isPrivate
          ? PRIVATE_REPORT_DUMMY_DETAILS
          : { isPrivate: false })}
        className={el`detail-card`}
      />

      <section className={el`share-on-twitter-section`}>
        <ShareOnTwitter
          username={p.authorUsername}
          imageSrc={p.profileImgSrc ?? '/images/avatar.svg'}
          defaultValue={`I just filled out a report about a ${
            p.category === ScamCategory.OTHER
              ? 'scam'
              : getDisplayStringForScamCategory(p.category)
          } on Chainabuse: ${getUrlForReport(p.reportId)}`}
        />
      </section>
    </div>
  );
}

type SuccessPageContainerProps = StyleProps & {
  reportId: string;
};

function SuccessPageContainer(props: SuccessPageContainerProps): ReactElement {
  const { data, loading } = useReportSuccessDetailsQuery({
    variables: { input: { id: props.reportId } },
  });
  const { me } = useMe();

  if (!data && !loading) {
    // @TODO handle error better -- maybe unknown server error
    return (
      <div className={el`server-error`}>
        <Text>Submitting report failed. Can not find report.</Text>
      </div>
    );
  }

  if (!data || !data?.report) {
    // @TODO handle error better
    return <div className={el`server-error`} />;
  }

  const scamReportCardProps = getScamReportCardPropsFromReport(data.report);
  const isGuestReport = data.report.reportedBy.username === 'chainabuse-guest';

  return (
    <SuccessPage
      {...scamReportCardProps}
      profileImgSrc={me?.photo?.url}
      className={props.className}
      isGuestReport={isGuestReport}
    />
  );
}

export default SuccessPageContainer;
